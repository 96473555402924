@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.form-container {
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: black;
  margin: auto;
  width: max-content;
  max-height: auto;
  opacity: 0; 
  animation: slideIn 1s forwards; 
}


/* Add animation keyframes for  from the bottom */
@keyframes slideIn {
  from {
    top: 100%; 
    opacity: 0;
  }
  to {
    top: 82%; 
    opacity: 1;
  }
}

.form-container .form-control,
.gradeDropDown,
.dropdown-toggle,
.form-btn {
  opacity: 0; 
  animation: fadeIn 1s forwards; 
}

/* Add animation keyframes for fading in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-container .form-control,
.gradeDropDown,
.dropdown-toggle,
.form-btn {
  animation-delay: 0.5s; 
}

.form-container .form-control {
  background-color: rgba(255, 192, 203, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
}

.gradeDropDown {
  background-color: #ad4080;
  border-radius: 8px;
  margin-bottom: 20px;
}

.dropdown:hover .dropdown-toggle {
  background-color: #ffc0cb;
  border-radius: 8px;
  margin-bottom: 20px;
  border-color: #ffc0cb;
}

.dropdown-toggle {
  background-color: #ffc0cb;
  border-color: #ffc0cb;
}

.form-container .form-control:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.form-container .form-control::placeholder {
  color: white;
}

.form-container .form-control {
  color: black;
}

.center-button {
  display: flex;
  justify-content: center;
}


.form-btn {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  text-align: center;
  color: #fff;
  font-family: "Acumin_Variable_Concept";
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  box-sizing: border-box;
  background: radial-gradient(circle, rgba(132, 80, 191, 1) 0%, rgba(224, 29, 160, 1) 100%);
  border: none;
  background-size: 400%;
  border-radius: 30px;
  z-index: 1;
}


@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}


@media (max-width: 768px) {
  .form-container {
    width: 80%; 
  }

  .gradeDropDown,
  .dropdown:hover .dropdown-toggle {
    width: 100%;
  }

  .Toastify__toast-container {
    width: 100% !important; 
    margin-left: auto;
    margin-right: auto;
  }
  .form-btn {
    width: 50%;
    height: 50px;
    line-height: 50px;
  }
}


@media (min-width: 769px) {
  .form-btn {
    width: 150px;
    height: 40px;
    line-height: 45px;
  }
}

