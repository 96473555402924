@keyframes moveDot {
    0%, 100% {
        left: 0;
    }
    50% {
        left: calc(100% - 5px);
    }
}

.line-container {
    width: 2cm;
    overflow: hidden;
}


.line {
    position: absolute;
    height: 3px;
    background-color: #ddd;
    width: 80px;
    display: flex;
    align-items: center;
}
.dot {
    position: relative;
    width:8px;
    height: 8px;
    background-color: #dd4086;
    border-radius: 50%;
    animation: moveDot 4s linear infinite; 
}

