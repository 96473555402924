@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: none;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-header strong {
  color: #58595b;
  font-size: 20px;
  font-weight: bold;
  font-family: "Acumin_Variable_Concept";
}

.accordion-button[aria-expanded="true"] strong {
  color: #e73284;
}

.accordion-Card-container {
  width: 20%;
  border-radius: 1rem;
  padding: 2rem;
  background-color: white;
  position: absolute;
  justify-content: center;
  bottom: -10%;
  gap: 1rem;
  transform: translate(-150%);
  left: 50%;
  box-shadow: 0 2px 8px 4px rgb(249, 244, 244, 0.45);
}

/* Exrta Section Styles */
.extra-section {
  position: relative;
  text-align: center;
  background-image: url("../../images/who-we-are-bg.webp");
  background-size: cover;
  background-position: center;
  color: #fff;
  min-height: 650px;
}

.extra-section h4 {
  color: white;
  margin-top: 25px;
  font-family: "Acumin_Variable_Concept";
  font-size: 25pt;

  font-weight: bold;
}

.extra-section .section-title {
  margin-top: 20px;
}

.extra-section h5 {
  font-size: 24px;
  font-family: "Acumin_Variable_Concept";
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: bold;
  color: white;
  line-height: 1.5;
}

.accordion-body h6 {
  font-family: "Acumin_Variable_Concept";
  color: #58595b;
  font-size: 12pt;
  font-weight: bold;
}

.accordion-body h5 {
  font-family: "Acumin_Variable_Concept";
  color: #58595b;
  font-size: 12pt;
  font-weight: bold;
}
.accordion-body p {
  font-family: "Acumin_Variable_Concept";
  color: #58595b;
  font-size: 12pt;
  position: relative;
  font-weight: medium;
}

.accordion-body {
  position: relative;
}

.read-more-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  margin-right: 15px;
  color: #e73284;
  border: none;
  font-weight: bold;
  background: none;
  font-family: "Acumin_Variable_Concept";
  background-color: none;
  cursor: pointer;
}

/* FAQ Section Styles */
.faq-style-1 {
  padding: 100px 0;
  margin-bottom: -6%;
}

.faq-style-1 {
  max-width: 100%;
  margin-top: -200px;
  position: relative;
  overflow: visible;
  z-index: 99;
  margin-right: auto;
  background: transparent;
  background-size: cover;
  background-position: center;
  background-image: url("../../images/About_bg.webp");
}

.who-we-are-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.image-card {
  overflow: hidden;
  width: 100%;
  height: 100%;
  animation: fadeIn 1s ease-in-out forwards;
}

.image-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}

.image-card:hover::before {
  transform: scaleX(1);
}

.image-card:not(:hover)::before {
  transform: scaleX(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .extra-section {
    background-image: url("../../images/Who-we-are-bg_Mobile.png");
    background: url("../../images/Who-we-are-bg_Mobile.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
  }

  .extra-section h4,
  .extra-section h5 {
    color: white;
  }
  .extra-section h5 {
    color: white;
    font-size: 15px;
    padding: 10px;
    padding-right: 20px;
    text-align: center;
  }

  .accordion-Card-container {
    width: 90%;
    padding: rem;
    transform: translate(-50%);
    left: 50%;
    bottom: 5%;
    top: 10%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
  }

  .faq-style-1 .container {
    margin-top: -95%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .accordion-Card-container strong {
    font-size: 16px;
  }

  .accordion-header strong {
    color: #58595b;
    font-size: 16px;
    font-weight: bold;
    font-family: "Acumin_Variable_Concept";
  }

  .accordion-body h6,
  .accordion-body h5,
  .accordion-body p {
    font-size: 12px;
    color: #58595b;
  }

  .read-more-btn {
    bottom: 5px;
    right: 5px;
    font-size: 14px;
  }
}
