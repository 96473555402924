/* @import '~bootstrap-icons/font/bootstrap-icons.css'; */

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family:  
  Roboto,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Acumin Variable Concept';
  src: local('Acumin-Variable-Concept'), url('./fonts/Acumin-Variable-Concept.ttf') format('truetype');
  font-weight: normal;;
  font-style: normal;
}


@media screen and (max-width: 667px) {
  body {
      overflow-x: hidden !important;
  }
  .container {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
}