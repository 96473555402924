/* Academics.css */

@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.academics-container-styles {
  background-color: #041e54;
  background-image: url("../../images/Academics.webp");
  background-size: cover;
  background-position: center;
  width: 100%; 
}

.academics-container-styles h4,
.text-center {
  font-family: "Acumin_Variable_Concept";
  color: white;
}

.card-container{
  margin-bottom: 40px;
}
.card-academics {
  overflow: hidden;
  border: none;
  overflow: hidden;
  border-radius: 25px;
  transform: translateY(50px);
  opacity: 0;
  max-width: 100%;
  height: 100%;
  border: 2px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  animation: fadeInUp 0.5s ease-in-out;
}

.card-img-academics {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.card-body {
  height: 3.75rem;
  background-color: #d24080;
  text-align: center;
  border-color: #d24080;
  border-radius: 0 0 15px 15px;
}


.card-academics.slide-in {
  transform: translateY(0);
  opacity: 1;
}

.card-academics:hover {
  transform: scale(1.05);
  filter: brightness(1.2);
  transition: transform 0.1s ease-in-out, filter 0.1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.additional-content {
  padding: 20px;
  border-color: none;
  border-radius: 15px;
  margin-bottom: 20px;
}

.card {
  border: none;
  border-radius: 15px;
}

/* Media Queries */

@media (max-width: 576px),
(min-width: 577px) and (max-width: 767px),
(min-width: 768px) and (max-width: 991px),
(min-width: 992px) and (max-width: 1199px) {
  .card-academics {
    margin-bottom: 15px;
  }
}



@media (max-width: 768px) {
  .academics-container-styles {
    height: auto;
  }

  .card-academics {
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    margin-bottom: 80px;
  }
}




@media (max-width: 576px) {
  .card-academics {
    max-width: 80%;
    margin-bottom: 50px; 
    margin-top: 30px;
  }

  
  .card-img-academics {
    border-radius: 10px 10px 0 0; 
  }
}

