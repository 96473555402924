@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.navbar {
  background-color: lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 4rem;
}

.navbar-brand img {
  height: 2.813rem;
  width: 120px;
  
}

.imglogo{
  height: 2.813;
  width: 100%;
}
.nav-link-custom {
  font-family: "Acumin_Variable_Concept";
  font-size: 16px;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 15px;
  cursor: pointer;
}

.nav-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

.nav-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: lightgray;
    width: 100%;
  }

  .navbar-links.open {
    display: flex;
  }

  .nav-toggle {
    display: block;
  }
}

.navbar {
  margin-bottom: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.toggle-line {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
}
.Navbar.Toggle:hover,
.Navbar.Toggle:focus {
  border-color: #dd4086 !important;
}

.nav-link-custom {
  cursor: pointer;
  color: #333;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
}

.nav-link-custom:hover {
  color: #dd4086;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar {
    margin-bottom: 10px;
  }
  .navbar-links {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: lightgray;
    width: 100%;
  }

  .navbar-links.open {
    display: flex;
  }


  .nav-toggle {
    display: block;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: white;
    position: absolute;
    top: 60px;
    right: 0;
  }

  .navbar-collapse.open {
    display: flex;
  }


  .navbar-brand img {
    height: 2.813rem;
    width: 90px;
    object-fit: contain;
    
  }
  .nav-link-custom {
    display: block;
    text-align: center;
    padding: 10px 15px;
    margin: 5px;
  }
}
