@import "~bootstrap-icons/font/bootstrap-icons.css";


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("./fonts/Acumin-Variable-Concept.ttf") format("truetype");
}


.form-container{
  font-family: Acumin_Variable_Concept;
  font-weight: medium;
}







