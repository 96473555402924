@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.about-container-styles {
  background-image: url("../../images/About_bg.webp");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.image-card {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border: none;
  border-radius: 50px;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out forwards;
}

.overlay-about-image {
  width: 9%;
  height: 7rem;
  background-image: url("../../images/about-img-bg.png");
}
.about-image-bg {
  width: 50%;
  height: 27px;
  background-image: url("../../images/about-img-bg.png");
}

.image-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}

.image-card:hover::before {
  transform: scaleX(1);
}

.image-card:not(:hover)::before {
  transform: scaleX(0);
}

.about-text {
  flex: 1;
  margin: 20px;
  margin-left: 40px;
}

.about-text h4 {
  margin-top: 20px;
  color: #d24080;
  font-family: "Acumin_Variable_Concept";
  font-size: 30px;
  font-weight: bold;
}

.about-text .important-text {
  font-family: "Acumin_Variable_Concept";
  font-size: 20px;
  font-weight: bolder;
  color: #58595B;
}

.about-text .medium-text {
  font-family: "Acumin_Variable_Concept";
  font-size: 15px;
  font-weight: medium;
  color: #58595B;
}

.about-image {
  margin-left: -20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add this to your About.css file */

@media (max-width: 768px) {
  .about-container-styles {
    padding: 20px;
  }

  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: justify;
  }

  .about-image {
    width: 100%;
  }

  .overlay-about-image {
    height: 100px;
  }

  .image-card {
    width: 80%;
    margin: 0 auto;
  }

  .about-text {
    margin-top: 20px;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .about-text .important-text{
    font-size: 1.3rem;
  }
  .about-text h4 {
    margin-top: 20px;
    text-align: center;
    align-items: center;
  }

  .about-text .medium-text{
    color: #58595B;
    font-size: 16px;
  }

  
  .about-animate {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
