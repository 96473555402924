/* HomeComponent.css */

.home-container {
  position: relative;
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow: hidden;
  margin-top: 3.75rem;
}

.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home-caption {
  position: absolute;
  top: 33%;
  right: -40%;
  transform: translateY(-50%);
  text-align: end;
  color: #041e54;
  max-width: 80%;
  margin-right: 40px;
  opacity: 0;
  animation: slideInFromRight 1s ease-out forwards;
}

.home-caption h1 {
  font-family: "Acumin_Variable_Concept";
  font-size: 3rem;
  line-height: 1;
  font-weight: bolder;
}

.form-container .form-control {
  width: 100%;
  background-color: rgba(173, 64, 128, 0.6);
  margin-bottom: 20px;
}

.form-container {
  width: 100%;
  height: 25%;
}

@keyframes slideInFromRight {
  from {
    right: -50%;
    opacity: 0;
  }
  to {
    right: 50px;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .home-container {
    padding: 0;
    margin-top: 0;
  }

  .home-caption {
    top: 15%;
    align-items: center;
  }

  .home-caption h1 {
    font-size: 3rem;
  }

  .home-image {
    min-height: 120vh;
    margin-bottom: 0px;
  }
  .form-container {
    width: 100%;
    height: 70%;
  }

  .form-container .form-control {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .home-container {
    margin-top: 0;
    padding: 0;
    margin: 0;
  }

  .home-caption {
    top: 15%;
    align-items: center;
  }

  .home-caption h1 {
    font-size: 1.3rem;
  }

  .home-image {
    min-height: 120vh;
    margin: 0;
  }
  .form-container {
    width: 100%;
    height: 65%;
  }

  .form-container .form-control {
    margin-bottom: 10px;
  }
}
