@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.finish-container {
  background-image: url("../../images/finish-edge-bg.webp");
  background-size: cover;
  background-position: center;
  background-color: #041e54;
  padding-top: 50px;
  color: #fff;
  width: 100%;
  max-height: auto;
  padding-bottom: 20px;
}

.finish-nav-menu {
  display: flex;
  justify-content: flex-start;
}

.finish-nav-menu .nav-item {
  flex: 1;
  margin: 0 3px;
}

.finish-nav-menu .nav-link {
  background-color: #d24080;
  color: white;
  font-family: "Acumin_Variable_Concept";
  font-size: 14pt;
  border-radius: 10px 10px 0 0;
  padding: 10px 15px;
}

.finish-nav-menu .nav-link.active {
  background-color: #fff;
  color: #000;
  font-family: "Acumin_Variable_Concept";
}

.nav-content-div {
  align-items: center;
}

.tab-content {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0 0 20px 20px;
}

.content-image {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
}


.title-finnish {
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: "Acumin_Variable_Concept";
}
.finnish-edge-content ul {
  list-style-type: square;
  list-style-position: inside;
  margin-top: 40px;
  padding-left: 0px;
  flex: 1;
  line-height: 2px;
  padding-left: 10px;
  margin-right: 30px;
  color: white;
}

.finnish-edge-content ul li {
  margin-bottom: 10px;
  margin-right: 20px;
  font-family: "Acumin_Variable_Concept";
  font-size: 16pt;
  line-height: 1;
  text-indent: -30px;
  opacity: 0;
  transform: translateX(-20px);
}

@keyframes fadeInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
} /* Apply the animation to all li elements */
.finnish-edge-content ul li {
  animation: fadeInLeft 0.5s forwards;
}

.finnish-edge-content ul li:nth-child(1) {
  animation-delay: 0.2s;
}

.finnish-edge-content ul li:nth-child(2) {
  animation-delay: 0.4s;
}

.finnish-edge-content ul li:nth-child(3) {
  animation-delay: 0.6s;
}

.finnish-edge-content ul li:nth-child(4) {
  animation-delay: 0.8s;
}
.finnish-edge-content ul li:nth-child(5) {
  animation-delay: 1s;
}

.finnish-edge-content .finish-card-image {
  opacity: 0;
  transform: translateX(-20px);
}

.finnish-edge-content .finish-card-image img {
  max-width: 100%;
  height: auto;
  padding: 10px;
  background-color: white;
  opacity: 0;
  transform: translateX(-20px);
}

@keyframes fadeInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.finnish-edge-content .finish-card-image {
  animation: fadeIn 0.5s forwards;
  animation-delay: 0.5s;
}

.finnish-edge-content .finish-card-image img {
  animation: fadeIn 0.5s forwards;
  animation-delay: 0.5s;
}
.finish-card-image {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finish-nav-menu .nav-item.active {
  transform: translateY(-5px); /* Adjust the offset as needed */
}

.finish-card-image img {
  max-width: 100%;
  height: auto;
  margin-right: -40px;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
}
.finish-card-image:hover img {
  transform: rotateY(180deg);
  margin-left: -40px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .tab-content {
    max-width: 1200px;
    margin: 0 auto;
  }

  .nav-content-div {
    justify-content: space-between;
  }

  .finish-card-image {
    flex: 1;
    max-width: 50%;
  }

  .finish-card-image img {
    max-width: 100%;
    height: auto;
    padding: 10px;
    background-color: white;
  }
}

@media (max-width: 992px) {
  .finish-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .title-finnish {
    font-size: 40px;
    margin-top: 15px;
    font-family: "Acumin_Variable_Concept";
  }

  .finish-nav-menu {
    flex-direction: column;
  }

  .finish-nav-menu .nav-item {
    flex: 1;
    margin: 0 0 5px 0;
  }

  .finish-nav-menu .nav-link {
    padding: 5px 10px;
  }

  .finish-card-image {
    max-width: 100%;
    margin-left: 25px;
  }

  .finish-card-image img {
    max-width: 100%;
    height: auto;
  }

  .tab-content {
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 50px;
  }

  .nav-content-div {
    flex-direction: column;
    align-items: center;
  }

  .tab-content ul {
    margin-top: 10px;
    font-size: 2rem;
    margin-right: 0;
    text-align: start;
    align-items: flex-start;
    justify-content: start;
  }

  .tab-content ul li {
    margin-bottom: 5px;
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 2rem;
    text-align: justify;
    justify-content: start;
  }
  h4 {
    font-size: 1.3rem;
    margin-top: 10px;
  }

  .finish-nav-menu .nav-link {
    background-color: #d24080;
    color: white;
    font-family: "Acumin_Variable_Concept";
    font-size: 18pt;
    border-radius: 10px 10px 0 0;
    padding: 10px 15px;
  }

  .animate-text {
    font-size: 18px;
  }

  .nav-content-div {
    flex-direction: column;
    align-items: center;
  }

  .nav-content-div h6 {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .finish-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .finish-nav-menu {
    flex-direction: column;
  }

  .finish-nav-menu .nav-item {
    flex: 1;
    margin: 0 0 5px 0;
  }

  .finish-nav-menu .nav-link {
    padding: 5px 10px;
  }

  .finish-card-image {
    max-width: 100%;
    margin-left: 25px;
  }

  .finish-card-image img {
    max-width: 100%;
    height: auto;
  }

  .tab-content {
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 30px;

  }

  .nav-content-div {
    flex-direction: column;
    align-items: center;
  }

  .tab-content ul {
    margin-top: 10px;
    font-size: 1rem;
    margin-right: 0;
    text-align: start;
    align-items: flex-start;
    justify-content: start;
  }

  .tab-content ul li {
    margin-bottom: 5px;
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 1rem;
    text-align: justify;
    justify-content: start;
  }
  h4 {
    font-size: 1.3rem;
    margin-top: 10px;
  }

  .animate-text {
    font-size: 18px;
  }

  .nav-content-div {
    flex-direction: column;
    align-items: center;
  }

  .nav-content-div h6 {
    margin-top: 10px;
  }
}
