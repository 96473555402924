/* Gallery.css */

@font-face {
  font-family: "Acumin_Variable_Concept";
  src: url("/src/fonts/Acumin-Variable-Concept.ttf");
}

.gallery-container {
  background-image: url("../../images/About_bg.webp");
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  
}

.finnish-image-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -11;
  background-repeat: repeat-x;
  height: 100px;
  background-image: url("../../images/about-img-bg.png");
  margin-top: -55px;
}

.slider-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-right: 20px;
}

.imageSlide {
  margin-left: 15px;
  box-sizing: border-box;
  height: 500px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out forwards;
}

.imglogo {
  height: inherit;
  object-fit: contain;
  width: auto;
}
.imageSlide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
  transform-origin: center center;
}

.imageSlide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}

.imageSlide:hover::before {
  transform: scaleX(1);
}

.imageSlide:not(:hover)::before {
  transform: scaleX(0);
}

.video-slide {
  margin-right: 15px;
  box-sizing: border-box;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out forwards;
  transition: transform 0.3s ease-in-out;
}

.video-slide:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.slick-slide img {
  width: 95%;
  height: 100%;
  object-fit: cover;
}

.custom-arrow {
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.custom-prev-arrow {
  left: -15%;
}

.custom-next-arrow {
  right: -15%;
}

.video-container {
  background-image: url("../../images/About_bg.webp");
  background-size: cover;
  background-position: center;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .slider-container {
    padding: 0 10px;
  }


  .custom-arrow {
    width: 30px;
    height: 30px;
    color: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }

  .imageSlide .custom-arrow,
  .video-slide .custom-arrow {
    display: flex;
  }

  .slick-slide img {
    width: 100%;
  }

  .video-slide {
    margin-bottom: 10px;
    width: 50%;
    height: 600px;
    position: relative;
    /* margin-right: 0; */
    height: 600px;
    margin-left: 3.75rem;
    padding-right: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-slide iframe {
    width: 100%;
    height: 100%;
  }
}


@media (max-width: 480px) {
  .finnish-image-bg {
    height: 30px;
    margin-top: -15px;
  }

  .slider-container {
    padding: 0;
    margin-right: -40px;
    margin-left: -40px;
  }

  .imageSlide .custom-arrow,
  .video-slide .custom-arrow {
    display: flex;
  }

  .custom-next-arrow {
    width: 20px;
    height: 20px;
    color: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-right: 30%;
    color: darkgray;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }

  .custom-prev-arrow {
    width: 20px;
    height: 20px;
    color: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin-left: 30%;
    color: darkgray;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }

  .slick-slide img {
    width: 100%;
    height: 600px;
    padding-right: 160px;
    margin-left: 65px;
  }
  .imageSlide {
    height: 500px;
    width: 100%;
    padding-right: 100px;
    padding-left: 100px;
    margin-bottom: 10px;
    position: relative;
  }
  .video-slide {
    height: 600px;
    width: 100%;
    padding-left: 120px;
    margin-bottom: 10px;
    position: relative;
  }

  .custom-prev-arrow {
    left: 2%;
  }

  .custom-next-arrow {
    right: 2%;
  }

  .video-slide iframe {
    width: 80%;
    height: 80%;
  }
}
