.dashed-line {
  border-right: 2px dashed white;
  padding-right: 15px;
}

footer {
  background-image: url("../../images/footer-bg.webp");
  background-size: cover;
  background-position: center;
  background-color: #041e54;
  color: white;
  bottom: 0;
  /* padding-top: 10px; */
  /* margin-top: 10px; */
  height: auto;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  margin-top: 50px;
  width: 60px;
  margin-right: 30px;
  height: 60px;
}

.icon-img {
  margin-top: 50px;
  width: 51px;
  height: 51px;
  cursor: pointer;
}

.logo-img {
  margin-top: 20px;
  width: 60px;
  height: 60px;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.5s ease-in-out;
    border-radius: 15%;

}

.footer-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.footer-text {
  color: white;
}

.icon-img {
  margin-top: 20px;
  width: 60px;
  height: 60px;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.5s ease-in-out;
  border-radius: 100%;
  margin-bottom: 10px;
}

/* Add hover effect with glow */
.logo-img:hover {
  transform: scale(1.2);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  border-radius: 15%;
}

.icon-img:hover {
  transform: scale(1.2);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
  border-radius: 100%;
}

/* Add click effect with glow */
.logo-img:active {
  transform: scale(0.8);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

.icon-img:active {
  transform: scale(0.8);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  border-radius: 100%;
}

.icon-img,
.logo-img {
  margin-top: 20px;
  width: 60px;
  height: 60px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

@media (max-width: 767px) {
  .dashed-line {
    border-right: none;
    border-bottom: 2px dashed white;
    padding-right: 0;
    margin-right: 0;
  }
  .dashed-line img,
  .dashed-line p {
    margin-top: 20px;
  }
}
